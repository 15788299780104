/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import farmsConfig, { addFarms } from 'config/constants/farms'
import fetchFarms from './fetchFarms'
import {
    fetchFarmUserEarnings,
    fetchFarmUserAllowances,
    fetchFarmUserTokenBalances,
    fetchFarmUserStakedBalances,
} from './fetchFarmUser'
import { FarmsState, Farm } from '../types'

const initialState: FarmsState = { data: [...farmsConfig] }

export const farmsSlice = createSlice({
    name: 'Farms',
    initialState,
    reducers: {
        setFarmsPublicData: (state, action) => {
            const liveFarmsData: Farm[] = action.payload
            // update data
            state.data = state.data.map((farm) => {
                const liveFarmData = liveFarmsData.find((f) => f.pid === farm.pid)
                return { ...farm, ...liveFarmData }
            })
            // add data
            const newFarmsData: Farm[] = liveFarmsData.filter((farm) => {
                return state.data.findIndex((f) => f.pid === farm.pid) === -1
            })
            state.data = state.data.concat(newFarmsData);
        },
        setFarmUserData: (state, action) => {
            const { arrayOfUserDataObjects } = action.payload
            arrayOfUserDataObjects.forEach((userDataEl) => {
                const { index } = userDataEl
                state.data[index] = { ...state.data[index], userData: userDataEl }
            })
        },
    },
})

// Actions
export const { setFarmsPublicData, setFarmUserData } = farmsSlice.actions

// Thunks
export const fetchFarmsPublicDataAsync = () => async (dispatch) => {
    const farms = await fetchFarms()
    dispatch(setFarmsPublicData(farms))
}
export const fetchFarmUserDataAsync = (account) => async (dispatch) => {
    const userFarmAllowances = await fetchFarmUserAllowances(account)
    const userFarmTokenBalances = await fetchFarmUserTokenBalances(account)
    const userStakedBalances = await fetchFarmUserStakedBalances(account)
    const userFarmEarnings = await fetchFarmUserEarnings(account)

    const arrayOfUserDataObjects = userFarmAllowances.map((farmAllowance, index) => {
        return {
            index,
            allowance: userFarmAllowances[index],
            tokenBalance: userFarmTokenBalances[index],
            stakedBalance: userStakedBalances[index],
            earnings: userFarmEarnings[index],
        }
    })

    dispatch(setFarmUserData({ arrayOfUserDataObjects }))
}

export const addFarmDatas = (farm: any) => async (dispatch) => {
    const isAdded = addFarms(farm);
    if (isAdded) dispatch(setFarmsPublicData(farmsConfig))
}
export default farmsSlice.reducer
